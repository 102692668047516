<template>
    <div>
        <!--        <full-page ref="fullpage" :options="options" id="fullpage" :aria-controls="true">-->
        <div class="section" id="home">
            <div class="pp-scrollable text-white section-c section-1">
                <div class="scroll-wrap">
                    <div class="section-bg">
                        <div class="video-container">
                            <!--                            <video data-autoplay loop muted playsinline class="wrapper__video" style="width: 100%;">-->
                            <video autoplay loop muted playsinline class="wrapper__video" style="width: 100%;">
                                <source src="https://sanish.xeoscript.com/video.mp4" type="video/mp4">
                            </video>
                        </div>
                        <!--                            <div class="video-background">-->
                        <!--                                <div class="video-foreground">-->
                        <!--                                    <iframe-->
                        <!--                                        src="https://www.youtube.com/embed/W0LHTWG-UmQ?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&playlist=W0LHTWG-UmQ"-->
                        <!--                                        frameborder="0" allowfullscreen></iframe>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                    </div>
                    <div class="scrollable-content ">
                        <div class="vertical-centred">
                            <div class="boxed boxed-inner">
                                <div class="vertical-title hidden-xs hidden-sm"><span>Introduce</span></div>
                                <div class="boxed">
                                    <div class="container">
                                        <div class="intro">
                                            <div class="row">
                                                <div class="col-lg-8 col-xl-6">
                                                    <p class="subtitle-top">
                                                        Voice Artist. Script Writer. <br>Reputation Management
                                                        Expert. Since 2005
                                                    </p>
                                                    <h1 class="display-2 text-white"><span class="text-primary">NAMASKARAM, </span><br>
                                                        THIS IS <br> SANISH BHASKARAN.
                                                    </h1>
                                                    <div class="hr-bottom"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section" id="aboutMe">
            <div class="pp-scrollable section-c section-2 bg-about">
                <div class="scroll-wrap">
                    <div class="scrollable-content">
                        <div class="vertical-centred">
                            <div class="boxed boxed-inner">
                                <div class="vertical-title text-dark hidden-xs hidden-sm"><span>what I do</span>
                                </div>
                                <div class="boxed">
                                    <div class="container">
                                        <div class="intro">
                                            <div class="row justify-content-xl-between text-white">
                                                <div class="col-lg-5 col-xl-6">
                                                    <p class="subtitle-top text-dark">About me</p>
                                                    <h2 class="title-uppercase">WAKE UP AND SMELL THE<br> <span
                                                        class="text-primary"> BRAND </span>
                                                        IN YOU!</h2>
                                                    <p>The love for advertisements got me started as a copywriter in
                                                        2005. A job that required me to ideate
                                                        and write for brands to sell. During the stint, I was part of
                                                        campaigns for leading corporates from the
                                                        South of India as clients. The writer in me guided me further to
                                                        the director in me and before I knew
                                                        I was producing 26 episodes of a news-based television show for
                                                        Asianet which aired every week.</p>
                                                    <p>Cut to 2007. The constant urge to learn and evolve enrolled me in
                                                        a full-time MBA curriculum from
                                                        the School of Management Studies, CUSAT. I was eventually
                                                        selected by Radio Mirchi (The Times of
                                                        India) to work on their special promotional assignments during
                                                        the course. And it was there I found
                                                        my voice, literally. I became a Voice artist and began recording
                                                        for ads, ads, and ads.</p>
                                                    <p class="mb-0">My body of work was diverse and interesting enough
                                                        for RK Swamy
                                                        BBDO to offer a job soon. I
                                                        joined as a Senior Brand Associate.</p>

                                                </div>
                                                <div class="col-lg-6 col-xl-6">
                                                    <p> A designation that demanded
                                                        me to think not just as a writer but
                                                        as a brand custodian.</p>
                                                    <p>After 5 years in advertising, I decided to be on the other side
                                                        of the table. And be a client myself. I
                                                        switched roles to being a Brand Manager and led the marketing
                                                        communications department of an
                                                        FMCD and IT company. A few years later I was offered an
                                                        opportunity to be part of a corporate
                                                        entity that was functioning in the nutraceutical sector
                                                        (Nutrition+ Pharmaceutical). As a Corporate
                                                        Communications Manager, my key responsibility was to manage
                                                        their reputation with a clientele
                                                        spanning over 65 countries worldwide. With a good additional 10
                                                        years on the client-side today, I
                                                        have been fortunate to see the world from both sides.</p>
                                                    <p>Additional to the formal responsibilities I have associated with
                                                        leading brands including the ones
                                                        from Aviation, Healthcare, Retail, and Entertainment. My role
                                                        being their trusted Reputation
                                                        Management Consultant.</p>
                                                    <p>I currently support individual professionals, entrepreneurs,
                                                        brands, trade bodies, celebrities in their
                                                        pursuit to handle reputation across multiple platforms and
                                                        venues both offline as well as online.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section" id="associatedBrands">
            <div class="pp-scrollable text-white section-c section-3 bg-asso">
                <div class="scroll-wrap">
                    <div class="bg-changer d-none d-lg-block">
                        <div class="overlay-1"></div>
                        <swiper class="swiper"
                                :style="{ 'height': `100%`, 'min-height': `${window.height}px`}"
                                :options="swiperOption1">
                            <swiper-slide>
                                <div class="section-bg bg-1"></div>
                            </swiper-slide>

                        </swiper>
                    </div>
                    <div class="scrollable-content">
                        <div class="vertical-centred">
                            <div class="boxed boxed-inner">
                                <div class="vertical-title hidden-xs hidden-sm"><span>Brands</span></div>
                                <div class="boxed">
                                    <div class="container">
                                        <div class="intro">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <h2 class="title-uppercase text-primary">Associated Brands</h2>
                                                    <div class="row-project-box row">

                                                        <div class="col-project-box col-md-6 col-lg-4 col-xl-3">
                                                            <a class="project-box">
                                                                <div class="">
                                                                    <h5>ASIANET</h5>
                                                                    <div class="project-category">Director- News Based
                                                                        Television Program (26 Episodes)
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div class="col-project-box col-md-6 col-lg-4 col-xl-3">
                                                            <a class="project-box">
                                                                <div class="">
                                                                    <h5>RADIO MIRCHI 98.3 FM</h5>
                                                                    <div class="project-category">Voice Artist, Script
                                                                        Writer
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div class="col-project-box col-md-6 col-lg-4 col-xl-3">
                                                            <a class="project-box">
                                                                <div class="">
                                                                    <h5> THE HINDU</h5>
                                                                    <div class="project-category">Brand Campaign
                                                                        Management
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div class="col-project-box col-md-6 col-lg-4 col-xl-3">
                                                            <a class="project-box">
                                                                <div class="">
                                                                    <h5>THE WEEK (MALAYALA MANORAMA)</h5>
                                                                    <div class="project-category">Voice Artist
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div class="col-project-box col-md-6 col-lg-4 col-xl-3">
                                                            <a class="project-box">
                                                                <div class="">
                                                                    <h5>KOCHI METRO</h5>
                                                                    <div class="project-category">Script Writer for
                                                                        Inaugural Launch Campaign
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div class="col-project-box col-md-6 col-lg-4 col-xl-3">
                                                            <a class="project-box">
                                                                <div class="">
                                                                    <h5>AIR INDIA EXPRESS</h5>
                                                                    <div class="project-category">Brand Activation
                                                                        Campaign
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div class="col-project-box col-md-6 col-lg-4 col-xl-3">
                                                            <a class="project-box">
                                                                <div class="">
                                                                    <h5> RK SWAMY BBDO</h5>
                                                                    <div class="project-category">Senior Brand Associate
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div class="col-project-box col-md-6 col-lg-4 col-xl-3">
                                                            <a class="project-box">
                                                                <div class="">
                                                                    <h5>ALLIANZ CORNHILL</h5>
                                                                    <div class="project-category">Campaign Management
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div class="col-project-box col-md-6 col-lg-4 col-xl-3">
                                                            <a class="project-box">
                                                                <div class="">
                                                                    <h5>TAJ HOTELS</h5>
                                                                    <div class="project-category">Brand Campaign
                                                                        Management
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div class="col-project-box col-md-6 col-lg-4 col-xl-3">
                                                            <a class="project-box">
                                                                <div class="">
                                                                    <h5>CGH EARTH</h5>
                                                                    <div class="project-category">Brand Campaign
                                                                        Management
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div class="col-project-box col-md-6 col-lg-4 col-xl-3">
                                                            <a class="project-box">
                                                                <div class="">
                                                                    <h5>KUMARAKOM LAKE RESORT</h5>
                                                                    <div class="project-category">Brand Campaign
                                                                        Management
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div class="col-project-box col-md-6 col-lg-4 col-xl-3">
                                                            <a class="project-box">
                                                                <div class="">
                                                                    <h5>ARJUNA NATURAL</h5>
                                                                    <div class="project-category">Corporate
                                                                        Communications
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>

                                                    </div>
                                                    <!--                                                    <a href="#" class="h5 link-arrow text-white">view all-->
                                                    <!--                                                        projects <i-->
                                                    <!--                                                            class="icon icon-chevron-right fa fa-chevron-circle-right"></i></a>-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section" id="media">
            <div class="pp-scrollable text-white section-c section-3 bg-2">
                <div class="scroll-wrap">
                    <div class="bg-changer">
                        <div class="overlay-1"></div>
                        <!--                        <swiper class="swiper"-->
                        <!--                                :style="{ 'height': `100%`, 'min-height': `${window.height}px`}"-->
                        <!--                                :options="swiperOption1">-->
                        <!--                            <swiper-slide>-->
                        <!--                                <div class="section-bg bg-2"></div>-->
                        <!--                            </swiper-slide>-->

                        <!--                        </swiper>-->
                    </div>
                    <div class="scrollable-content">
                        <div class="vertical-centred">
                            <div class="boxed boxed-inner">
                                <div class="vertical-title hidden-xs hidden-sm"><span>Media</span></div>
                                <div class="boxed">
                                    <div class="container">
                                        <div class="intro">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <h2 class="title-uppercase text-primary">Media Coverage </h2>
                                                    <small class="text-primary d-lg-none"
                                                           style="margin: -34px 0  25px; font-size: 1rem; display: block;">Click
                                                        on each title to view the news</small>
                                                    <div class="row-project-box row">
                                                        <CoolLightBox
                                                            :items="itemsLarge"
                                                            :index="index"
                                                            @close="index = null">
                                                        </CoolLightBox>

                                                        <div class="col-project-box col-md-6 col-lg-4 col-xl-3"
                                                             v-for="(image, imageIndex) in items"
                                                             :key="imageIndex">
                                                            <a class="project-box project-box-c py-5"
                                                               @click="index = imageIndex">
                                                                <img :src="image" class="img-fluid" alt="">
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section d-none" id="page7">
            <div class="pp-scrollable section-c section-4">
                <div class="scroll-wrap">
                    <div class="scrollable-content">
                        <div class="vertical-centred">
                            <div class="boxed boxed-inner">
                                <div class="vertical-title text-dark hidden-xs hidden-sm"><span>Partners</span>
                                </div>
                                <div class="boxed">
                                    <div class="container">
                                        <div class="intro overflow-hidden">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="row-partners">
                                                        <div class="col-partner">
                                                            <div class="partner-inner">
                                                                <img alt=""
                                                                     src="../assets/images/partners/01.jpg">
                                                            </div>
                                                        </div>
                                                        <div class="col-partner">
                                                            <div class="partner-inner">
                                                                <img alt=""
                                                                     src="../assets/images/partners/02.jpg">
                                                            </div>
                                                        </div>
                                                        <div class="col-partner">
                                                            <div class="partner-inner">
                                                                <img alt=""
                                                                     src="../assets/images/partners/03.jpg">
                                                            </div>
                                                        </div>
                                                        <div class="col-partner">
                                                            <div class="partner-inner">
                                                                <img alt=""
                                                                     src="../assets/images/partners/04.jpg">
                                                            </div>
                                                        </div>
                                                        <div class="col-partner">
                                                            <div class="partner-inner">
                                                                <img alt=""
                                                                     src="../assets/images/partners/05.jpg">
                                                            </div>
                                                        </div>
                                                        <div class="col-partner">
                                                            <div class="partner-inner">
                                                                <img alt=""
                                                                     src="../assets/images/partners/06.jpg">
                                                            </div>
                                                        </div>
                                                        <div class="col-partner">
                                                            <div class="partner-inner">
                                                                <img alt=""
                                                                     src="../assets/images/partners/07.jpg">
                                                            </div>
                                                        </div>
                                                        <div class="col-partner">
                                                            <div class="partner-inner">
                                                                <img alt=""
                                                                     src="../assets/images/partners/08.jpg">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section" id="contact">
            <div class="pp-scrollable section-c bg-contact section-6">
                <div class="scroll-wrap">
                    <div class="scrollable-content">
                        <div class="vertical-centred">
                            <div class="boxed boxed-inner">
                                <div class="vertical-title text-dark hidden-xs hidden-sm"><span>contact</span>
                                </div>
                                <div class="boxed">
                                    <div class="container">
                                        <div class="intro overflow-hidden">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <h2 class="title-uppercase">Get In Touch</h2>
                                                    <div class="contact-info">
                                                        <transition name="fade" mode="out-in">
                                                            <div class="alert alert-success"
                                                                 v-if="state === 'S'" key="'S">
                                                                <div
                                                                    class="d-flex justify-content-center align-items-center">
                                                                    <div class="text-center p-4">
                                                                        <!--                                        <img src="../assets/img/positive-vote.svg" class="img-fluid w-50 my-4" alt="#">-->
                                                                        <p class="c-font-19 mb-4 proxim-light">
                                                                            The mail has been successfully
                                                                            submitted.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="alert alert-danger"
                                                                 v-else-if="state === 'F'" key="'F">
                                                                <div
                                                                    class="d-flex justify-content-center align-items-center">
                                                                    <div class="text-center p-4">
                                                                        <!--                                        <img src="../assets/img/error.svg" class="img-fluid w-50 my-4" alt="#">-->
                                                                        <p class="c-font-19 mb-4 proxim-light">
                                                                            The mail has been failed.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="alert alert-info"
                                                                 v-else-if="state === 'L'" key="'L">
                                                                <div
                                                                    class="d-flex justify-content-center align-items-center">
                                                                    <div class="text-center p-4">
                                                                        <loading-animation/>
                                                                        <p class="c-font-19 mb-4 proxim-light">
                                                                            Please wait while communicate with
                                                                            the
                                                                            server</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <form class="js-form" role="form" v-else
                                                                  @submit.prevent="onSubmit">
                                                                <!--                                                                        <form class="js-form" novalidate="novalidate">-->
                                                                <div class="row">
                                                                    <div class="form-group col-md-6">
                                                                        <input type="text" name="name"
                                                                               required placeholder="Name*"
                                                                               v-model="name">
                                                                    </div>
                                                                    <div class="form-group col-md-6">
                                                                        <input type="text" name="phone"
                                                                               placeholder="Phone"
                                                                               v-model="phone" required>
                                                                    </div>
                                                                    <div class="form-group col-md-12">
                                                                        <input type="email" name="email"
                                                                               placeholder="Email"
                                                                               v-model="email">
                                                                    </div>
                                                                    <div class="form-group col-md-12">
                                                                                <textarea name="message" required
                                                                                          placeholder="Message*"
                                                                                          aria-required="true"
                                                                                          v-model="message">
                                                                                </textarea>
                                                                    </div>
                                                                    <div class="col-md-12">
                                                                        <button type="submit" class="btn">
                                                                            Post Comment
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </transition>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--        </full-page>-->
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import axios from 'axios';
import LoadingAnimation from '../components/LoadingAnimation';
import img1 from '../assets/images/news/1.jpeg';
import img11 from '../assets/images/news/01.png';
import img2 from '../assets/images/news/2.jpeg';
import img22 from '../assets/images/news/02.png';
import img3 from '../assets/images/news/3.jpg';
import img33 from '../assets/images/news/03.png';
import img8 from '../assets/images/news/8.jpg';
import img88 from '../assets/images/news/08.png';
import img9 from '../assets/images/news/9.jpg';
import img99 from '../assets/images/news/09.png';
import img10 from '../assets/images/news/10.jpg';
import img100 from '../assets/images/news/010.png';

export default {
    name: 'Home',
    metaInfo: {
        title: 'Home'
    },
    components: {
        Swiper,
        SwiperSlide,
        LoadingAnimation
    },
    data: function () {
        return {
            state: 'D',
            name: '',
            phone: '',
            email: '',
            subject: '',
            message: '',
            options: {
                menu: '#menu',
                anchors: ['page1', 'page2', 'page3', 'page4', 'page5'],
                controlArrows: true,
                // scrollBar: true,
                scrollOverflow: true,
                setAllowScrolling: true,
                navigation: true,
                verticalCentered: true,
                sectionsColor: ['#ccc', '#fff'],
                fixedElements: '#header, .footer',
                responsiveWidth: 0,
                responsiveHeight: 0,
                responsiveSlides: false,
                parallax: false,
                parallaxOptions: { type: 'reveal', percentage: 62, property: 'translate' },
                dropEffect: false,
                dropEffectOptions: { speed: 2300, color: '#F82F4D', zIndex: 9999 },
                cards: false,
                cardsOptions: { perspective: 100, fadeContent: true, fadeBackground: true }
            },
            swiperOption1: {
                centeredSlides: true,
                effect: 'fade',
                fadeEffect: { crossFade: true },
                speed: 3000,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false
                },
                slidesPerView: 1,
                spaceBetween: 0,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    },
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    },
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    }
                }
            },
            window: {
                height: window.innerHeight
            },
            items: [
                img100, img11, img22, img33, img88, img99
            ],
            itemsLarge: [
                img10, img1, img2, img3, img8, img9
            ],
            index: null
        };
    },
    methods: {
        onSubmit () {
            const bodyFormData = new FormData();
            bodyFormData.append('name', this.name);
            bodyFormData.append('phone', this.phone);
            bodyFormData.append('email', this.email);
            bodyFormData.append('subject', this.subject);
            bodyFormData.append('message', this.message);

            const that = this;
            this.state = 'L';

            axios({
                method: 'post',
                url: 'https://sanish.xeoscript.com/mail.php',
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(function (response) {
                that.onSubmitSuccess(response);
            }).catch(function (response) {
                that.onSubmitFailed(response);
            });
        },

        onSubmitSuccess () {
            this.state = 'S';
        },

        onSubmitFailed () {
            this.state = 'F';
        }
    }

};
</script>

<style>
.bg-banner {
    background-image: url(../assets/images/bg/bg1.jpg);
}

.bg-about {
    background-image: url(../assets/images/About-Me.jpg) !important;
}

.bg-contact {
    background-image: url(../assets/images/Contact.jpg) !important;
    background-size: cover !important;
}

.bg-1 {
    background-image: url(../assets/images/Associated-Brands.jpg);
}

.bg-2 {
    background-image: url(../assets/images/Media-Coverage.jpg) !important;
}

.bg-3 {
    background-image: url(../assets/images/bg/bg2-3.jpg);
}

.bg-4 {
    background-image: url(../assets/images/bg/bg2-4.jpg);
}

.bg-5 {
    background-image: url(../assets/images/bg/bg2-5.jpg);
}

.bg-6 {
    background-image: url(../assets/images/bg/bg2-6.jpg);
}

.bg-changer .swiper {
    z-index: 0;
}

@media (max-width: 992px) {
    .bg-asso {
        background-image: url(../assets/images/Associated-Brands.jpg) !important;
        background-attachment: fixed !important;
    }

    .bg-2 {
        background-image: url(../assets/images/Associated-Brands-mob.jpg) !important;
        background-attachment: fixed !important;
    }

    /* .bg-2 {*/
    /*    background-image: url(../assets/images/About-Me.jpg) !important;*/
    /*}*/
}

</style>
